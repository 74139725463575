<template>
    <div class="page1">
        <EForm
                :formColumns="formColumns"
                :rowSize="4"
                :optionsBtn="true"
                :actionBtn="false"
                :formData="searchForm"
                ref="form"
                @onSearch="onSearch"
                :searchFlag="true"
                :exportShow="false"
        >
        </EForm>
        <!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
        <!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
        <ETable
                :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size"
        >
            <div slot="urlImg" slot-scope="scope">
                <viewer :images="[scope.data.urlImg]">
                    <img :src="scope.data.urlImg" alt="" class="img">
                </viewer>
            </div>
            <div slot="cardImg" slot-scope="scope">
                <viewer :images="[scope.data.cardImg]">
                    <img :src="scope.data.cardImg" alt="" class="img">
                </viewer>
            </div>
            <div slot="registProvince" slot-scope="scope">
                {{scope.data.registProvince+scope.data.registCity+scope.data.registArea}}
            </div>
            <div slot="curentProvince" slot-scope="scope">
                {{scope.data.curentProvince+scope.data.curentCity+scope.data.curentArea}}
            </div>
            <div slot="injectionProveImg" slot-scope="scope">
                <viewer :images="[scope.data.injectionProveImg]">
                    <img :src="scope.data.injectionProveImg" alt="" class="img">
                </viewer>
            </div>
            <div slot="status" slot-scope="scope">
                <span v-if="scope.data.status==0">
                    待审核
                </span>
                <span v-if="scope.data.status==1">
                    通过
                </span>
                <span v-if="scope.data.status==2">
                    拒绝
                </span>
            </div>
            <el-table-column fixed="right" label="操作" width="180px">
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="dialogShow(scope.row)">编辑</EButton>
                        <EButton type="text" @click="remove(scope.row.id)">删除</EButton>

                    </div>
                </template>
            </el-table-column>
        </ETable>
        <el-dialog
                title="编辑"
                :visible.sync="dialogVisible"
                width="50%"
                :before-close="()=>this.dialogVisible = false">
            <EForm :formColumns="dialogFormColumns"
                   :labelPosition="labelPosition"
                   :rowSize="1"
                   :optionsBtn="false"
                   :labelWidth="labelWidth"
                   :actionBtn="true"
                   :formData="form"
                   :formRules="formRules"
                   ref="form"
                   @handleClick="saveData"
                   @handleClose="cancelDialog"
                   @getCode="getCode()"
                   :searchFlag="false"
                   @changeRadio="changeRadio"
                   @getImgPropList="getImgPropList"
            ></EForm>

            <span slot="footer" class="dialog-footer">
         <el-button type="primary" @click="auditFn">保存</el-button>
    <el-button @click="dialogVisible = false">取 消</el-button>

  </span>
        </el-dialog>
        <el-dialog
                title="请填写拒绝原因"
                :visible.sync="dialogRefuseVisible"
                width="60%"
                :before-close="()=>this.dialogRefuseVisible = false">
            <div>
                <el-form>
                    <el-form-item label="">
                        <el-input
                                type="textarea"
                                :rows="3"
                                placeholder="请输入内容"
                                v-model="remark">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
         <el-button type="primary" @click="auditFn(2)">确 定</el-button>
    <el-button @click="dialogRefuseVisible = false">取 消</el-button>

  </span>
        </el-dialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import {identity_type, status} from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import {otherMixin} from '@/components/mixin';
  import {mapGetters} from 'vuex'

  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '人脸图片',
            prop: 'urlImg',
            type: 'slot'
          },
          {
            label: '身份证图片',
            prop: 'cardImg',
            type: 'slot'
          },
          {
            label: '新冠疫苗注射凭证',
            prop: 'injectionProveImg',
            type: 'slot'
          },
          {
            label: '姓名',
            prop: 'name',
          },
          {
            label: '人员身份',
            prop: 'businessType',
            width:180
          },
          {
            label: '手机',
            prop: 'mobile',
            width:180
          },
          {
            label: '身份证号',
            prop: 'cardNo',
            width:180
          },
          {
            label: '性别',
            prop: 'gender',
          },
          {
            label: '年龄',
            prop: 'age',
          },

          {
            label: '拒绝原因',
            prop: 'remark',
            width:180
          },
          {
            label: '审核结果',
            prop: 'status',
            type: 'slot',
            width:180
          },
          {
            label: '审核时间',
            prop: 'auditTime',
          },
          {
            label: '审核员',
            prop: 'auditPerson',
          },
          {
            label: '申请时间',
            prop: 'createTime',
          },
          {
            label: '注册时间',
            prop: 'createTime',
          },
          {
            label: '籍贯',
            prop: 'registProvince',
            type: 'slot',
            width:180
          },
          {
            label: '居住地',
            prop: 'curentProvince',
            type: 'slot',
            width:180
          },
          {
            label: '详细地址',
            prop: 'currentPlace',
            width:180
          },


        ],
        formColumns: [
          {
            title: '姓名',
            type: 'text',
            property: 'name',
            show: true,
          },
          {
            title: '手机号  ',
            type: 'text',
            property: 'mobile',
            show: true,
          },
          {
            title: '身份证号',
            type: 'text',
            property: 'cardNo',
            show: true,
          },
          // {
          //   title: '人员身份',
          //   type: 'select',
          //   property: 'businessType',
          //   show: true,
          //   options: [
          //     {
          //       label: '全部',
          //       value: '',
          //     },
          //     {
          //       label: '供应商',
          //       value: '供应商',
          //     },
          //     {
          //       label: '采购商',
          //       value: '采购商',
          //     },
          //     {
          //       label: '摆渡车',
          //       value: '摆渡车',
          //     },
          //     {
          //       label: '司机/伙计',
          //       value: '司机/伙计',
          //     },
          //     {
          //       label: '中央市场办公人员',
          //       value: '中央市场办公人员',
          //     },
          //   ],
          // },
          {
            title: '申请起始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true,
          },
          {
            title: '申请截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true,
          },
          {
            title: '性别',
            type: 'select',
            property: 'gender',
            show: true,
            options: [{
              label: '全部',
              value: '',
            },
              {
                label: '男',
                value: '男',
              },
              {
                label: '女',
                value: '女',
              },],
          },
          {
            title: '审核员',
            type: 'select',
            property: 'auditPersonId',
            show: true,
            options:[]
          },
          {
            title: '审核开始时间',
            type: 'datePicker',
            property: 'auditTimeStart',
            show: true,
          },
          {
            title: '审核截止时间',
            type: 'datePicker',
            property: 'auditTimeEnd',
            show: true,
          },
          {
            title: '审核结果',
            type: 'select',
            property: 'status',
            show: true,
            options: [{
              label: '全部',
              value: '',
            },
              {
                label: '通过',
                value: '1',
              },
              {
                label: '拒绝',
                value: '2',
              },],
          },
        ],
        dialogFormColumns: [
          {
            title: '姓名',
            type: 'text',
            property: 'name',
            show: true,
          },
          {
            title: '人员身份',
            type: 'select',
            property: 'businessType',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '供应商',
                value: '供应商',
              },
              {
                label: '采购商',
                value: '采购商',
              },
              {
                label: '摆渡车',
                value: '摆渡车',
              },
              {
                label: '司机/伙计',
                value: '司机/伙计',
              },
              {
                label: '中央市场办公人员',
                value: '中央市场办公人员',
              },
            ],
          },
          {
            title: '手机号',
            type: 'text',
            property: 'mobile',
            show: true,
          },
          {
            title: '身份证号',
            type: 'text',
            property: 'cardNo',
            show: true,
          },
          {
            title: '籍贯',
            type: 'cityCascader',
            property: 'registerCode',
            checkStrictly: false,
            show: true,
          }, {
            title: '居住地',
            type: 'cityCascader',
            property: 'currentCode',
            show: true,
          }, {
            title: '详细地址',
            type: 'text',
            property: 'currentPlace',
            show: true,
          }, {
            title: '人脸图片',
            type: 'imgUpLoad',
            property: 'urlImg',
            show: true,
          }, {
            title: '身份证图片',
            type: 'imgUpLoad',
            property: 'cardImg',
            show: true,
          },
          {
            title: '新冠疫苗注射凭证',
            type: 'imgUpLoad',
            property: 'injectionProveImg',
            show: true,
          },{
            title: '审核结果',
            type: 'radio',
            property: 'status',
            inputProp:'remark',
            show: true,
            options: [
              {
                label: '通过',
                value: 1
              },
              {
                label: '拒绝',
                value: 2,
                inputShow:true,
              }
            ]
          },
        ],
        labelPosition: 'left',
        labelWidth: '120px',
        formRules: {
          // boothCode: vxRule(true, '', "blur", "摊位编号不能为空"),
        },
        form: {
          remark:''
        },
        tableData: [],
        searchForm: {
          name: null,
          mobile: null,
          cardNo: null,
          gender: null,
          createTimeStart: null,
          createTimeEnd: null,
          auditTimeStart: null,
          auditTimeEnd: null,
          businessType: "中央市场办公人员",
          auditPersonId:null,
          status:'',
          offset: 1,
          limit: 10,
        },
        count: null,
        exportData: {},
        dialogVisible: false,
        dialogRefuseVisible: false,
        remark: '',
        id: ''

      };
    },
    watch: {},
    created() {
      this.getData();
      this.userLabel()
    },
    components: {ETable, EDialog, EButton, EForm},
    computed: {
      ...mapGetters(['buttonList']),
    },
    beforeMount() {
    },
    methods: {
      getImgPropList(val){
        this.form[val.prop]=val.urlList.length>0?val.urlList.toString():''
      },
      changeRadio(){
        if(this.form.status==1){
          this.form.remark=''
        }
      },
      getCode(){
        console.log(this.form)
      },
      remove(id) {
        this.$messageBox
            .confirm('确认删除?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => {
              this.auditRemove(id);
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              });
            });
      },
      async auditRemove(id) {
        let res = await Http.auditRemove({
          id
        })
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      saveData() {
      },
      cancelDialog() {
        this.id = ''
        this.dialogVisible = false
        this.dialogRefuseVisible = false
      },
      async dialogShow(item) {
        this.auditDetail(item.id)


      },
      async auditDetail(id) {
        let res = await Http.auditDetail({
          id: id,

        })
        if (res.code == 200) {
          let data=res.data
          this.form = {
            id:data.id,
            name: data.name,
            mobile: data.mobile,
            businessType: data.businessType,
            cardNo: data.cardNo,
            currentPlace: data.currentPlace,
            urlImg: data.urlImg,
            cardImg: data.cardImg,
            status: data.status,
            injectionProveImg:data.injectionProveImg,
            registerCode:data.registProvinceId?[Number(data.registProvinceId),Number(data.registCityId),Number(data.registAreaId)]:[],
            currentCode:data.curentProvinceId?[Number(data.curentProvinceId),Number(data.curentCityId),Number(data.curentAreaId)]:[],
            remark:data.remark
          }
          setTimeout(()=>{
            this.dialogVisible = true
          },200)

        }
      },
      async auditFn() {
        let {registerCode,currentCode}=this.form
        if(registerCode.length>0){
          this.form.registProvinceId=registerCode[0]
          this.form.registCityId=registerCode[1]
          this.form.registAreaId=registerCode[2]
        }
        if(currentCode.length>0) {
          this.form.curentProvinceId = currentCode[0]
          this.form.curentCityId = currentCode[1]
          this.form.curentAreaId = currentCode[2]
        }
        let res = await Http.auditSave(this.form)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.cancelDialog()
          this.getData()
        }
      },
      onSearch() {
        this.searchForm.offset = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.offset = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.limit = size;
        this.getData();
      },

      async getData() {

        let res = await Http.auditList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.rows;
          this.count = res.data.total;
        }
      },
      async userLabel() {
        let res = await Http.userLabel({
          // roleId: 7
        })
        if (res.code == 200) {
          this.formColumns[6].options = res.data
        }
      },

    }
  };
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }

    .img {
        width: 100%;
        /*height: 100px;*/
    }
</style>
